module.exports = function () {
    if ($(".promotion-banner > .promotion-slide").length) {
        $(".promotion-banner").slick({
            dots: false,
            infinite: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 500,
            swipeToSlide: false,
            swipe: false,
            touchMove: false,
            fade: true,
        });

        if ($(".search-mobile").is(":visible")) {
            $(".header").after($(".promotion-banner"));
        }
    }

    function promotionBannerTimer() {
        setInterval(() => {
            var getEndDate = $(".promotion-timer").attr("data-promotime");

            var ts1 = new Date().getTime();
            var ts2 = new Date(getEndDate).getTime();

            var d, h, m, s;

            var ms = ts2 - ts1;
            if (ms <= 0) {
                $(".promotion-banner").hide();
                return;
            }
            s = Math.floor(ms / 1000);
            m = Math.floor(s / 60);
            s = s % 60;
            h = Math.floor(m / 60);
            m = m % 60;
            d = Math.floor(h / 24);
            h = h % 24;

            if (d < 10) {
                d = "0" + d;
            }
            if (h < 10) {
                h = "0" + h;
            }
            if (m < 10) {
                m = "0" + m;
            }
            if (s < 10) {
                s = "0" + s;
            }
            var promotionTimerUpdated = d + "d" + " : " + h + "h" + " : " + m + "m" + " : " + s + "s";
            $(".promotion-timer").html(promotionTimerUpdated);
        }, 1000);
    }
    if ($(".promotion-timer").length) {
        promotionBannerTimer();
    }
};

var peTracking = require("../tracking/pushEngageTrackingEvent");

function pushEngageScriptEachPageLoad() {
    try {
        var divPEFields = document.getElementsByClassName("user nav-item hover-dropdown");
        if (divPEFields.length == 1) {
            var data = divPEFields[0].dataset.subscriberhashurl;
            peTracking.pushEngageEvent("SAVE_SUBSCRIBER_HASH", data);
        }
        peTracking.pushEngageEvent("STOP_EVENT_INACTIVE_30_DAYS");
        peTracking.pushEngageEvent("STOP_EVENT_INACTIVE_90_DAYS");
        peTracking.pushEngageEvent("STOP_EVENT_INACTIVE_180_DAYS");
    } catch (e) {
        console.log("issue while calling PE script after page load: "+ e);
    }
}

module.exports = {
    pushEngageScriptEachPageLoad: pushEngageScriptEachPageLoad
};

/* eslint-disable quotes */
var util = require("../util");
var clientSideValidation = require("@sfra/js/components/clientSideValidation");
var dyHelper = require("../dynamicyield/dy");

var $document = $(document);
var $body = $(document.body);

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked to add a product to the wishlist
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = "alert-success";
    } else {
        status = "alert-danger";
    }

    if ($(".add-to-wishlist-messages").length === 0) {
        $body.append(
            "<div class=\"add-to-wishlist-messages \"></div>"
        );
    }
    $(".add-to-wishlist-messages")
        .append("<div class=\"add-to-wishlist-alert text-center " + status + "\">" + data.msg + "</div>");

    setTimeout(function () {
        $(".add-to-wishlist-messages").remove();
        button.removeAttr("disabled");
    }, 5000);
}

function addWishlist(currentCtrl, isMobile) {
    if (!$(".sign-in-menu-container").find(".greeting-item").length) {
        $(".add-wishlist-process").removeClass("add-wishlist-process");
        currentCtrl.parents(".product-detail").addClass("add-wishlist-process");
        wishlistLoginProcess();
    } else {
        if ($(".pdp-nav-carousel .nav-item img").length > 0) {
            var tileImg = $($(".pdp-nav-carousel .nav-item img")[0]).clone();
            $("#add-to-wishlist-popup .wishlist-popup-image").html(tileImg);
        }
        if (isMobile)
        {
            if ($('#quickViewModal').length !== 0) {
                $('#quickViewModal').modal("hide");
            }
        }

        var pid = $(".product-quickview .product-id").html();
        $("#hd-product-id").val(pid);
        $("#add-to-wishlist-popup").modal("show");
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getLoginModalHtmlElement() {
    if ($("#loginModal").length !== 0) {
        $("#loginModal").remove();
    }

    var htmlString = "<!-- Modal -->"
    + "<div class=\"modal fade\" id=\"loginModal\" tabindex=\"-1\" role=\"dialog\">"
    + "<span class=\"enter-message sr-only\" ></span>"
    + "<div class=\"modal-dialog login-dialog\">"
    + "<!-- Modal content-->"
    + "<div class=\"modal-content\">"
    + "<div class=\"modal-header\">"
    + "    <button type=\"button\" class=\"close pull-right\" data-dismiss=\"modal\">"
    + "        <span aria-hidden=\"true\">&times;</span>"
    + "        <span class=\"sr-only\"> </span>"
    + "    </button>"
    + "</div>"
    + "<div class=\"modal-body\"></div>"
    + "</div>"
    + "</div>"
    + "</div>";

    $body.append(htmlString);
}

/**
 * Do login process by Login popup, if user tries adding product to wishlist
 */
function wishlistLoginProcess() {
    $.spinner().start();
    getLoginModalHtmlElement();
    var url = util.appendToUrl($(".sign-in-menu-container .user a").attr("href"), {format: "ajax"});
    url = util.appendToUrl(url, { reload: false, wishlistSource: "wishlist" });
    $.ajax({
        url: url,
        type: "get",
        dataType: "html",
        success: function (data) {
            if (util.isDataJson(data)) {
                data = JSON.parse(data);
                window.location.href = data.redirectUrl;
            } else {
                $("#loginModal .modal-body").append(data);
                $("#loginModal").modal("show");
                clientSideValidation.invalid();
                $.spinner().stop();
                return;
            }
        },
        error: function error() {
            $.spinner().stop();
        },
    });
}

function wishlistUpdateIds() {
    var url = $(".wishlist-info-wrap").attr("data-url");

    $.ajax({
        url: url,
        type: "get",
        dataType: "html",
        success: function (data) {
            $(".wishlist-info-wrap").html(data);
        },
    });
}


module.exports = {
    init: function () {
        $document.on("click", ".remove-wishlist-widget-btn button", function (e) {
            e.preventDefault();
            var owlItem = $(this).parents(".owl-item");
            var itemImg = $(this).parents(".product-info").find(".product-image").clone();
            var data = {};
            data.url = $(this).data("url");
            data.pid = $(this).data("pid");
            data.owl = $(owlItem).index();
            $("#remove-from-wishlist-popup button.remove-wishlist").data(data);
            $("#remove-from-wishlist-popup .wishlist-popup-image").html(itemImg);
            $("#remove-from-wishlist-popup").modal("show");
        });

        $document.on("click", ".qvm-wishlist-add button", function (e) {
            e.preventDefault();
            addWishlist($(this), true);
        });

        $document.on("click", ".form-input-overlay", function (e) {
            e.preventDefault();
            if (!$(".sign-in-menu-container").find(".greeting-item").length) {
                wishlistLoginProcess();
            }
        });

        $document.on("wishlist:update_icon liveTV:productsRendered liveTV:auctionRendered", function () {
            if ($(".wishlistProductIDs").length) {
                var idList = $(".wishlistProductIDs").val().split(",");
                $(".product-tile-wrapper").each(function () {
                    var pid;

                    if ($(this).find(".product").length > 0 && $(this).find(".product").data("pid")) {
                        pid = $(this).find(".product").data("pid").toString();
                    } else if ($(this).data("product-id")) {
                        pid = $(this).data("product-id").toString();
                    }

                    if (idList.indexOf(pid) > -1) {
                        $(this).find(".wishlistTile").addClass("d-none");
                        $(this).find(".remove-wishlist-btn").removeClass("d-none");
                    }
                    else {
                        $(this).find(".wishlistTile").removeClass("d-none");
                        $(this).find(".remove-wishlist-btn").addClass("d-none");
                    }
                });
                $(".live-tv-pdp-top").each(function () {
                    var pid;

                    pid = $("#selectedProductID").text();
                    if (idList.indexOf(pid) > -1) {
                        $(".live-tv-pdp-section").find(".wishlistTile").addClass("d-none");
                        $(".live-tv-pdp-section").find(".remove-wishlist-btn").removeClass("d-none");
                    }
                    else {
                        $(".live-tv-pdp-section").find(".wishlistTile").removeClass("d-none");
                        $(".live-tv-pdp-section").find(".remove-wishlist-btn").addClass("d-none");
                    }
                });
            }
        });
    },
    addToWishlist: function () {
        $document.on("click", ".add-to-wish-list, .custom-add-wishlist-btn", function (e) {
            e.stopImmediatePropagation();
            e.preventDefault();

            if (!$(".sign-in-menu-container").find(".greeting-item").length) {
                $(".add-wishlist-process").removeClass("add-wishlist-process");
                $(this).parents(".product-detail").addClass("add-wishlist-process");
                wishlistLoginProcess();
            } else {
                var pid;
                var productDetail;
                var url = $("#add-to-wishlist-popup .add-to-wish-list").data("href");
                var button = $(this);
                var selectedID = $(".wishlist-item-sizes option:selected").val();
                if ($("#quickViewModal.modal.show").length > 0) {
                    productDetail = $("#quickViewModal .product-detail");
                } else {
                    productDetail = $(this).closest(".product-detail");
                }
                if (!$("#liveTvPDPSection").length && !$(".search-results").length) {
                    productDetail = $(".product-detail");
                }
                if (productDetail.length == 0) {
                    pid = $(this).closest(".product").data("pid");

                    if ($(".product-grid").find(".product[data-pid='" + pid + "']").length > 0) {
                        productDetail = $(".product-grid").find(".product[data-pid='" + pid + "']");
                    } else {
                        productDetail = $(".shop-last-tile[data-product-id='" + pid + "']");
                    }
                } else {
                    pid = $(productDetail).find(".product-id").html();
                    pid = pid ? pid.trim() : pid;
                }
                if (!pid) {
                    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
                        pid = $('.modal-content').find('.product-quickview').data('pid');
                    } else if ($('.product-set-detail').length || $('.product-set').length) {
                        pid = $(e).closest('.product-detail').find('.product-id').text();
                    } else if ($("#liveTvPDPSection").length) {
                        pid = $("#selectedProductID").text();
                    } else {
                        pid = $('.product-detail:not(".bundle-item")').data('pid');
                    }
                }
                var optionId = $(productDetail).find(".product-option").attr("data-option-id");
                var optionVal = $(productDetail).find(".options-select option:selected").attr("data-value-id");
                optionId = optionId || null;
                optionVal = optionVal || null;
                if (!url || !pid) {
                    return;
                }
                $("#wishlistModal").modal("hide");
                $("#add-to-wishlist-popup").modal("hide");
                if ($(".page").attr("data-action")=="ProgramGuide-ShowDailyView") {
                    $(".wishlist-modal-info").on("hidden.bs.modal", function () {
                        $body.addClass("modal-open");
                    });
                }
                $.spinner().start();
                $(this).attr("disabled", true);
                $.ajax({
                    url: url,
                    type: "post",
                    dataType: "json",
                    data: {
                        pid: pid,
                        optionId: optionId,
                        optionVal: optionVal,
                        selectedID: selectedID
                    },
                    success: function (data) {
                        $.spinner().stop();
                        if (data && data.error) {
                            displayMessage(data, button);
                            return;
                        }
                        $(button).removeAttr("disabled");
                        $(productDetail).find(".wl-toggle").toggleClass("d-none");
                        $("#add-to-wishlist-success-popup").modal("show");
                        $(".live-tv-shop-last-section").addClass("remove-wishlist-css");
                        setTimeout(function () {
                            $("#add-to-wishlist-success-popup").modal("hide");
                            $(".live-tv-shop-last-section").removeClass("remove-wishlist-css");
                            if ($("#liveTvPDPSection").length) {
                                $body.trigger("wishlist:update_icon");
                            }
                            $(".product-tile-wrapper .product[data-pid='" + pid + "']").find(".remove-wishlist-btn").removeClass("d-none");
                        }, 2000);
                        wishlistUpdateIds();
                        dyHelper.fireDYEvent("ADD_TO_WISHLIST", { pid: pid });
                    },
                    error: function (err) {
                        displayMessage(err, button);
                    }
                });
            }
        });
    },
    removeFromWishlist: function () {
        $document.on("click", ".remove-wishlist .remove-wishlist", function (e) {
            e.preventDefault();
            var url = $(this).data("url");
            var pid = $(this).data("pid");
            var owlIndex = $(this).data("owl");
            var button = $(this);
            if (!url || !pid) {
                return;
            }
            $("#remove-from-wishlist-popup").modal("hide");
            $.spinner().start();
            $(this).attr("disabled", true);
            $.ajax({
                url: url,
                type: "get",
                dataType: "json",
                data: {pid: pid},
                success: function () {
                    $.spinner().stop();
                    $(button).removeAttr("disabled");
                    $(".wishlist-carousel").trigger("remove.owl.carousel", [owlIndex]).trigger("refresh.owl.carousel");
                    $("#remove-wishlist-popup").modal("show");
                    if ($(".wishlist-carousel .owl-item").length == 0) {
                        location.reload();
                    }
                    setTimeout(function () {
                        $("#remove-wishlist-popup").modal("hide");
                        if ($("#liveTvPDPSection").length) {
                            $body.trigger("wishlist:update_icon");
                        }
                    }, 1500);
                },
                error: function (err) {
                    displayMessage(err, button);
                }
            });
        });
    },
    removeWishlistFromTile: function () {
        $document.on("click", ".remove-wishlist-btn, #removeWishlistItem .remove-wishlist", function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var url = $("#removeWishlistItem .remove-wishlist").data("url");
            var button = $(this);
            var pid, productDetail;

            if ($("#quickViewModal.modal.show").length > 0) {
                productDetail = $("#quickViewModal .product-detail");
            } else {
                productDetail = $(this).closest(".product-detail");
            }
            pid = $(productDetail).find(".product-id").html();
            if (!pid) {
                pid = $(this).closest(".product").data("pid");

                if ($(".product-grid").find(".product[data-pid='" + pid + "']").length > 0) {
                    productDetail = $(".product-grid").find(".product[data-pid='" + pid + "']");
                } else {
                    productDetail = $(".shop-last-tile[data-product-id='" + pid + "']");
                }
                if (!pid && $("#liveTvPDPSection").length) {
                    pid = $("#selectedProductID").text();
                }
            }
            if (!url || !pid) {
                return;
            }
            $("#removeWishlistItem").modal("hide");
            $(".live-tv-shop-last-section").removeClass("remove-wishlist-css");
            $.spinner().start();
            $(this).attr("disabled", true);
            $.ajax({
                url: url,
                type: "get",
                dataType: "json",
                data: {
                    pid: pid
                },
                success: function () {
                    $.spinner().stop();
                    $(button).removeAttr("disabled");
                    $(productDetail).find(".wl-toggle").toggleClass("d-none");
                    $("#remove-wishlist-popup").modal("show");
                    $(".live-tv-shop-last-section").addClass("remove-wishlist-css");
                    setTimeout(function () {
                        $(".live-tv-shop-last-section").removeClass("remove-wishlist-css");
                        $("#remove-wishlist-popup").modal("hide");
                        if ($(".page").attr("data-action")=="ProgramGuide-ShowDailyView") {
                            $(".wishlist-modal-info").on("hidden.bs.modal", function () {
                                $body.addClass("modal-open");
                            });
                        }
                        if ($(".page").data("action") === "Wishlist-Show") {
                            $(".pid-" + pid).remove();
                            if ($(".wishlistItemCards").length) {
                                window.scrollTo(0, 0);
                                location.reload();
                            }
                        }
                        if ($("#liveTvPDPSection").length) {
                            $body.trigger("wishlist:update_icon");
                        }
                        $(".product-tile-wrapper .product[data-pid='" + pid + "']").find(".remove-wishlist-btn").addClass("d-none");
                        $(".product-tile-wrapper .product[data-pid='" + pid + "']").find(".custom-add-wishlist-btn").removeClass("d-none");
                    }, 1500);
                    wishlistUpdateIds();
                },
                error: function (err) {
                    displayMessage(err, button);
                }
            });
        });
    }
};

var processInclude = require("base/util");
var gtmHelper = require("./gtm/gtm");
var pushEngageHelper = require("./pushEngage/pushEngageHelper");
//var gaHelper = require("./tracking/googleanalytics");

$(document).ready(function () {
    processInclude(require("./home/carousel"));
    processInclude(require("./components/promotionBanner"));
    processInclude(require("./product/fastBuy"));
    processInclude(require("./product/wishlist"));
    $(".card.product-info").on("mouseover", function () {
        $(this).find(".hover-section").show();
    });
    $(".card.product-info, .dy-product-tile").on("mouseout", function () {
        $(this).find(".hover-section").hide();
    });

    $(document).on("mouseover", ".dy-product-tile", function () {
        $(this).find(".hover-section").show();
    });
    $(document).on("mouseout", ".dy-product-tile", function () {
        $(this).find(".hover-section").hide();
    });

    if ($("#homepage-wishlist-popups").length > 0 && $("#maincontent").length > 0) {
        $("#maincontent").append($("#homepage-wishlist-popups"));
    }
    gtmHelper.fireGtmEvent("HOME");
    pushEngageHelper.pushEngageScriptEachPageLoad();
    /*gaHelper.fireGAEvent("HOME");
    setTimeout(function () {
        gaHelper.fireGAEvent("HOME_RECOMM");
    }, 15000);*/
});

require("./thirdParty/owl.carousel.min");
require("./thirdParty/jquery.zoom.min");
